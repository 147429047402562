import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import emailjs from "emailjs-com";
import { useState } from "react";
import { BsArrowRight, BsTelephone } from "react-icons/bs";
import { GiWorld } from "react-icons/gi";
import { HiOutlineMail } from "react-icons/hi";

export const Contact = () => {
  const defaultFormData = {
    fullname: "",
    email: "",
    subject: "",
    phone: "",
    interested: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [isSendBtnDisabled, setIsSendBtnDisabled] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // "success" | "error" | "warning" | "info"
  });

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isValidEmail = (email: string) =>
    /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);

  const sendEmail = (e: any) => {
    e.preventDefault();

    if (!formData.fullname) {
      setSnackbar({
        open: true,
        message: "Please enter your name.",
        severity: "error",
      });
      return;
    }

    if (!formData.email || !isValidEmail(formData.email)) {
      setSnackbar({
        open: true,
        message: "Please enter a valid email address.",
        severity: "error",
      });
      return;
    }

    if (!formData.interested) {
      setSnackbar({
        open: true,
        message: "Please describe your interest.",
        severity: "error",
      });
      return;
    }

    setIsSendBtnDisabled(true);

    emailjs
      .send(
        "service_ez66qzn",
        "template_a4hk5m4",
        {
          to_name: "Vista Fusion Studio",
          from_name: formData.fullname,
          message: formData.interested,
          subject: formData.subject,
          phone: formData.phone,
          email: formData.email,
        },
        "R9Yl9lyT79sQieGAV"
      )
      .then(
        () => {
          setSnackbar({
            open: true,
            message: "Message sent successfully!",
            severity: "success",
          });
          setFormData(defaultFormData);
        },
        () => {
          setSnackbar({
            open: true,
            message: "Failed to send message. Please try again later.",
            severity: "error",
          });
        }
      )
      .finally(() => {
        setIsSendBtnDisabled(false);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Container maxWidth="md">
      <Box mt={12} mb={4}>
        <Typography variant="h4" align="center" gutterBottom fontWeight="600">
          Contact Us
        </Typography>
        <Typography variant="h6" align="center" color="textSecondary">
          We are excited to meet new clients <br /> and bring your vision to
          life.
        </Typography>
      </Box>

      <Box component="form" onSubmit={sendEmail} mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              variant="standard"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              variant="standard"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              variant="standard"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              variant="standard"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Hello, I am interested in..."
              name="interested"
              value={formData.interested}
              onChange={handleChange}
              variant="standard"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              type="submit"
              endIcon={<BsArrowRight />}
              disabled={isSendBtnDisabled}
              sx={{
                color: "#CDA274",
                backgroundColor: "#292F36",
                "&:hover": { backgroundColor: "#1a202c" },
                fontSize: { xs: "0.85rem", sm: "1rem" },
              }}
            >
              Send Now
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        <Typography variant="subtitle1" component="div">
          {/* Email */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            mb={2}
          >
            <HiOutlineMail style={{ color: "#D44638", fontSize: "1.5rem" }} />
            <a
              href="mailto:contact@vistafusionstudio.com"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography color="textPrimary">
                contact@vistafusionstudio.com
              </Typography>
            </a>
          </Box>
          {/* Phone */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            mb={2}
          >
            <BsTelephone style={{ color: "#4CAF50", fontSize: "1.5rem" }} />
            <Typography component="span" color="textPrimary">
              +91-9030356464
            </Typography>
          </Box>
          {/* Website */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <GiWorld style={{ color: "#2196F3", fontSize: "1.5rem" }} />
            <a
              href="https://www.vistafusionstudio.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography component="span" color="textPrimary">
                www.vistafusionstudio.com
              </Typography>
            </a>
          </Box>
        </Typography>
      </Box>
      <Box mt={4} textAlign="center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3804.7697215070184!2d78.30128627555037!3d17.518502699013943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb8d8fc51310d7%3A0x161cba0b8f3f3265!2sVista%20Fusion%20Studio!5e0!3m2!1sen!2sin!4v1732974989465!5m2!1sen!2sin"
          title="map"
          width="100%"
          height="350px"
          style={{ border: 0 }}
          allowFullScreen
        />
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity as "success" | "error"}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Embedded Google Map */}
    </Container>
  );
};

export default Contact;
