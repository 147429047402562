import {
  FaClipboardList,
  FaHome,
  FaStore,
  FaCubes,
  FaCouch,
  FaPaintBrush,
} from "react-icons/fa";

export const services = {
  services: [
    {
      service_name: "Project Planning",
      service_content:
        "Every detail is planned meticulously from start to finish for a smooth and stress-free project journey.",
      icon: FaClipboardList,
    },
    {
      service_name: "Interior Design & Renovation",
      service_content:
        "Spaces are transformed into stylish, functional environments tailored to meet unique needs and preferences.",
      icon: FaHome,
    },
    {
      service_name: "Retail Design",
      service_content:
        "Branded and captivating retail spaces are designed to enhance customer experience and strengthen identity.",
      icon: FaStore,
    },
    {
      service_name: "2D/3D Visualization",
      service_content:
        "Realistic visualizations bring your ideas to life, offering a clear preview of your space before work begins.",
      icon: FaCubes,
    },
    {
      service_name: "Custom Furniture & Decor",
      service_content:
        "Unique furniture and decor pieces are designed to perfectly blend style, functionality, and personal taste.",
      icon: FaCouch,
    },
    {
      service_name: "Decor & Styling",
      service_content:
        "Perfect finishing touches are added, creating a cohesive, inviting space that truly reflects your personality.",
      icon: FaPaintBrush,
    },
  ],
};

export const thoughts = {
  people: [
    {
      fullname: "Lipsa Panda",
      country: "Hyderabad, India",
      thoughts:
        "The team brought our vision to life with incredible detail and architecture.",
    },
    {
      fullname: "Ramesh",
      country: "Hyderabad, India",
      thoughts:
        "Amazing craftsmanship and a seamless design process. Truly impressive work!",
    },
    {
      fullname: "Yashwant",
      country: "Hyderabad, India",
      thoughts:
        "A perfect blend of style and comfort. The interiors exceeded my expectations!",
    },
    {
      fullname: "Vamshi",
      country: "Hyderabad, India",
      thoughts:
        "The designs were creative, and the execution was flawless. Loved the outcome!",
    },
    {
      fullname: "Shekhar",
      country: "Hyderabad, India",
      thoughts:
        "Attention to detail and quality materials made all the difference. Great job!",
    },
    {
      fullname: "Uday",
      country: "Hyderabad, India",
      thoughts:
        "Elegant and functional. Our space feels completely transformed. Thank you!",
    },
    {
      fullname: "Valleshwar Rao",
      country: "Hyderabad, India",
      thoughts: "Professional team and stunning results. Highly recommended!",
    },
    {
      fullname: "Chavli",
      country: "Hyderabad, India",
      thoughts:
        "They understood our needs and delivered beyond expectations. Fantastic work!",
    },
    {
      fullname: "Venkatesh",
      country: "Hyderabad, India",
      thoughts:
        "Creative designs and efficient execution. Our home feels brand new!",
    },
    {
      fullname: "Murty",
      country: "Hyderabad, India",
      thoughts:
        "The team transformed our space into something truly remarkable and good!",
    },
    {
      fullname: "KAKV Rao",
      country: "Hyderabad, India",
      thoughts:
        "Superb quality and attention to detail. Couldn't be more satisfied!",
    },
    {
      fullname: "Sharma",
      country: "Hyderabad, India",
      thoughts:
        "The interiors are both stylish and functional. Great experience!",
    },
    {
      fullname: "Ranga",
      country: "Hyderabad, India",
      thoughts:
        "The entire process was smooth, and the results were outstanding. Thank you!",
    },
    {
      fullname: "Mahesh",
      country: "Hyderabad, India",
      thoughts:
        "The design team brought fresh ideas that worked perfectly for our space. Great job!",
    },
    {
      fullname: "Balamurgan",
      country: "Hyderabad, India",
      thoughts:
        "Beautiful designs and excellent execution. The team exceeded my expectations!",
    },
  ],
};
