import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";

// Example image data (in JSON format)
const imageData = [
  {
    url: "https://i.ibb.co/gFBtHRL/Washbasin-Drawingroom-New-logo.png",
  },
  {
    url: "https://i.ibb.co/TH9vwy0/PPK-TV-logo.png",
  },
  {
    url: "https://i.ibb.co/jgGKXyw/PPK-kitchen-logo.png",
  },
  {
    url: "https://i.ibb.co/V2fLCLj/PPK-Ground-V2-Living-room-20241110-205839-logo.png",
  },
  {
    url: "https://i.ibb.co/y4B9NVg/PPK-Ground-V2-Dining-room-20241110-225706-logo.png",
  },
  {
    url: "https://i.ibb.co/LQxh1kh/MBR-1-logo.png",
  },
  {
    url: "https://i.ibb.co/V3hWjW0/PPK-Ground-V2-Dining-room-20241110-204121-logo.png",
  },
  {
    url: "https://i.ibb.co/fn041cz/Slide4.jpg",
  },
  {
    url: "https://i.ibb.co/85yt8m5/Slide3.jpg",
  },
  {
    url: "https://i.ibb.co/bJG6Vr2/Slide1.jpg",
  },
  {
    url: "https://i.ibb.co/FgGBng0/Slide2.jpg",
  },
];

const Gallery = ({ images = imageData }) => {
  return (
    <Container maxWidth="lg">
      <Box mt={12} mb={4}>
        <Typography variant="h4" align="center" gutterBottom fontWeight={"600"}>
          Our Gallery
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          gutterBottom
        >
          Explore our latest projects
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr", // Single column for mobile screens
            sm: "repeat(2, 1fr)", // Two columns for small screens
            md: "repeat(3, 1fr)", // Three columns for desktop screens
          },
          gap: 2,
          // padding: 1,
          paddingLeft: 2,
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image.url}
            alt={`Gallery image ${index + 1}`}
            sx={{
              width: "100%",
              aspectRatio: "4 / 3", // Maintain consistent aspect ratio
              borderRadius: 2,
              boxShadow: 3,
              objectFit: "cover",
              transition: "transform 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default Gallery;
