import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import {
  Container,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

interface LinkItem {
  name: string;
  path: string;
}

const socialLinks = [
  {
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/share/1BH5WrTomp/?mibextid=LQQJ4d/",
  },
  {
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/vistafusionstudio/",
  },
  // { icon: <TwitterIcon />, url: "https://www.twitter.com/" },s
  { icon: <LinkedInIcon />, url: "https://www.linkedin.com/" },
];

const pageLinks: LinkItem[] = [
  { name: "About Us", path: "/services" },
  { name: "Contact Us", path: "/contact" },
  // { name: "Services", path: "/services" },
];

const serviceLinks: LinkItem[] = [
  { name: "Kitchen", path: "/gallery" },
  { name: "Living Area", path: "/gallery" },
  { name: "Bathroom", path: "/gallery" },
  { name: "Dining Hall", path: "/gallery" },
  { name: "Bedroom", path: "/gallery" },
];

function SocialMediaLinks() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <List
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        marginTop: "8%",
        marginLeft: isMobile ? "15%" : "0",
      }}
    >
      {socialLinks.map((social, index) => (
        <ListItem key={index} sx={{ display: "inline-flex", padding: 0 }}>
          <Link
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            {social.icon}
          </Link>
        </ListItem>
      ))}
    </List>
  );
}

interface LinkListProps {
  links: LinkItem[];
}

const LinkList: React.FC<LinkListProps> = ({ links }) => (
  <List
    sx={{
      padding: 0,
      textAlign: { xs: "center", sm: "left" },
    }}
  >
    {links.map((link, index) => (
      <ListItem key={index} sx={{ padding: 0, mb: 1.5, listStyle: "none" }}>
        <Link
          component={RouterLink}
          to={link.path}
          color="inherit"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          sx={{
            textDecoration: "none",
            color: "#4d5053",
            "&:hover": { color: "black" },
            fontFamily: "sans-serif",
          }}
        >
          {link.name}
        </Link>
      </ListItem>
    ))}
  </List>
);

export const Footer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <footer
      style={{
        width: "100%",
        backgroundColor: "#f8f8f8",
        paddingBottom: "2%",
        marginTop: "4%",
      }}
    >
      <Container
        maxWidth={false}
        sx={{ width: "100%", paddingX: { xs: "16px", md: "40px" } }}
      >
        <Grid
          container
          spacing={{ xs: 2, sm: 3, md: 4 }}
          sx={{
            width: "100%",
            margin: "0 auto",
            justifyContent: { xs: "center", sm: "space-between" },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Grid item xs={12} sm={4} md={3}>
            {/* <RouterLink
              to="/"
              style={{ textDecoration: "none", color: "black" }}
            >
              {true ? (
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: "100%",
                    maxWidth: "120px",
                    marginLeft: isMobile ? "0px" : "70px",
                  }}
                />
              ) : null}
            </RouterLink> */}
            {/* <Typography
              variant="body2"
              sx={{
                fontWeight: 300,
                marginTop: "8px",
                color: "#4d5053",
                fontSize: { xs: "14px", sm: "16px" },
              }}
            >
              Your home should be a reflection of who you are, and a collection
              of what you love.
            </Typography> */}
            <SocialMediaLinks />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: { xs: "16px", sm: "18px" },
                color: "#292f36",
                mr: isMobile ? 30 : 0,
                fontWeight: "600",
              }}
            >
              Pages
            </Typography>
            <LinkList links={pageLinks} />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "600",
                fontSize: { xs: "16px", sm: "18px" },
                color: "#292f36",
                mr: isMobile ? 30 : 0,
              }}
            >
              Services
            </Typography>
            <LinkList links={serviceLinks} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography
              variant="subtitle1"
              sx={{
                // fontFamily: "DM Serif Display",
                fontSize: { xs: "16px", sm: "18px" },
                color: "#292f36",
                fontWeight: "600",
              }}
            >
              Contact
            </Typography>
            <Typography
              sx={{
                marginBottom: 1,
                color: "#4d5053",
              }}
            >
              Villa - 437 Praneeth Pranav Knightwoods, Beeramguda, Hyderabad,
              Telangana 502032
            </Typography>
            <Link
              href="mailto:contact@vistafusionstudio.com"
              color="inherit"
              sx={{
                display: "block",
                color: "#4d5053",
                textDecoration: "none",
                marginTop: "8px",
              }}
            >
              contact@vistafusionstudio.com
            </Link>
            <Typography
              sx={{
                color: "#4d5053",
              }}
            >
              +91-9030356464
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          align="center"
          sx={{
            fontFamily: "Jost",
            color: "#4d5053",
            fontSize: "14px",
            mt: 2,
            mb: 4,
          }}
        >
          © {new Date().getFullYear()} Vista Fusion Studio
        </Typography>
      </Container>
    </footer>
  );
};
