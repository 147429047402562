import React, { useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Contact } from "./components/Contact/Contact";
import { Footer } from "./components/Footer/Footer";
import Modal from "./components/Modal"; // Import the modal component
import NavBar from "./components/NavBar";
import Gallery from "./components/Gallery/Gallery";
import Services from "./components/Services/Services";
import { FaWhatsapp } from "react-icons/fa"; // Import FontAwesome WhatsApp icon

const App: React.FC = () => {
  const [showModal, setShowModal] = useState(true);

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/+919030356464", "_blank");
  };

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Navigate to="/services" replace />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="*" element={<Navigate to="/services" replace />} />
      </Routes>
      <Footer />

      {showModal && <Modal open={showModal} setOpen={setShowModal} />}

      {/* WhatsApp Icon */}
      <div
        style={{
          position: "fixed",
          bottom: "10vw", // Adjust the bottom distance as needed
          right: "0px", // Attach it to the window border with a small margin
          zIndex: 1000,
          backgroundColor: "#25D366",
          borderTopLeftRadius: "50%",
          borderBottomLeftRadius: "50%",
          padding: "15px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
        }}
        onClick={handleWhatsAppClick}
        title="Chat on WhatsApp"
      >
        <FaWhatsapp style={{ color: "white", fontSize: "24px" }} />
      </div>
    </Router>
  );
};

export default App;
