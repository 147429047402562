import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

export default function Modal(props: any) {
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        BackdropProps={{
          style: { backgroundColor: "rgba(0, 0, 0, 0.85)" }, // Set the backdrop color to solid black
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{
            p: "0px !important",
            m: 0,
          }}
        >
          <Box
            key={0}
            component="img"
            src={"https://i.ibb.co/0J1y7F2/ADV.png"}
            // alt={`Gallery image ${index + 1}`}̦
            sx={{
              width: "100%",
              p: 0,
              m: 0,
              //   aspectRatio: "4 / 3", // Maintain consistent aspect ratio
              //   borderRadius: 2,
              //   boxShadow: 3,
              objectFit: "contain",
              //   transition: "transform 0.3s ease",
            }}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
