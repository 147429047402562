import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { services, thoughts } from "../../data/constants";
import companyProfile from "../../images/backgroung/companyProfile.jpg";
import galleryImg from "../../images/backgroung/galleryNavigate.jpg";
import photoBg from "../../images/backgroung/landingBg.jpg";
import customer1 from "../../images/person.jpg";

import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Thoughts = ({ thoughts, customer }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const visibleCount = isMobile ? 3.9 : 6.7;
  const totalCards = thoughts.people.length;

  // Handles going to the previous slide
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalCards - visibleCount : prevIndex - 1
    );
  };

  // Handles going to the next slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= totalCards - visibleCount ? 0 : prevIndex + 1
    );
  };

  return (
    <Box
      sx={{
        py: 5,
        textAlign: "center",
        overflow: "hidden",
        background: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        mb={4}
        fontWeight="600"
        sx={{
          fontSize: isMobile ? "1.5rem" : "2rem",
        }}
      >
        What People Think About Us
      </Typography>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: isMobile ? 2 : 8,
          flexDirection: isMobile ? "column" : "row",
          margin: "0 30px",
        }}
      >
        {/* Navigation Buttons */}
        {!isMobile && (
          <IconButton
            onClick={handlePrev}
            disabled={currentIndex === 0}
            sx={{
              backgroundColor: "#803341",
              color: "white",
              "&:hover": { backgroundColor: "#5c2630" },
              "&:focus": {
                backgroundColor: "#803341",
              },
              "&:disabled": {
                backgroundColor: "#ccc", // Disabled background color
                color: "#999", // Disabled text/icon color
                pointerEvents: "none", // Prevent hover or click interactions
              },
            }}
          >
            <ArrowBack />
          </IconButton>
        )}

        {/* Card Slider */}
        <Box
          sx={{
            overflow: "hidden",
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              transform: `translateX(-${currentIndex * (100 / visibleCount)}%)`,
              transition: "transform 0.5s ease-in-out",
              width: `${(totalCards / visibleCount) * 100}%`,
            }}
          >
            {thoughts.people.map((person: any, index: number) => (
              <Box
                key={index}
                sx={{
                  flex: `0 0 ${100 / visibleCount}%`,
                  boxSizing: "border-box",
                  px: 2,
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    p: 3,
                    border: "2px solid #803341",
                    minHeight: "130px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack
                    direction={isMobile ? "column" : "row"}
                    alignItems="center"
                    spacing={2}
                  >
                    <img
                      src={customer}
                      alt="customer"
                      style={{
                        width: isMobile ? 40 : 50,
                        height: isMobile ? 40 : 50,
                        borderRadius: "50%",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: isMobile ? "center" : "left",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: isMobile ? "0.9rem" : "1rem" }}
                      >
                        {person.fullname}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ fontSize: isMobile ? "0.75rem" : "0.875rem" }}
                      >
                        {person.country}
                      </Typography>
                    </Box>
                  </Stack>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      textAlign: "justify",
                      textJustify: "inter-word",
                      fontSize: isMobile ? "0.85rem" : "0.95rem",
                      mt: 2,
                    }}
                  >
                    {person.thoughts}
                  </Typography>
                </Paper>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Navigation Buttons */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "100px",
          }}
        >
          {isMobile && (
            <IconButton
              onClick={handlePrev}
              disabled={currentIndex === 0}
              sx={{
                backgroundColor: "#803341",
                color: "white",
                "&:hover": { backgroundColor: "#5c2630" },
                "&:focus": { backgroundColor: "#803341" },
                "&:disabled": {
                  backgroundColor: "#ccc", // Disabled background color
                  color: "#999", // Disabled text/icon color
                  pointerEvents: "none", // Prevent hover or click interactions
                },
              }}
            >
              <ArrowBack />
            </IconButton>
          )}

          <IconButton
            onClick={handleNext}
            disabled={currentIndex >= totalCards - visibleCount}
            sx={{
              backgroundColor: "#803341",
              color: "white",
              "&:hover": { backgroundColor: "#5c2630" },
              "&:focus": { backgroundColor: "#803341" },
              "&:disabled": {
                backgroundColor: "#ccc", // Disabled background color
                color: "#999", // Disabled text/icon color
                pointerEvents: "none", // Prevent hover or click interactions
              },
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export const Services: React.FC = () => {
  return (
    <Container disableGutters maxWidth={false}>
      {/* Main Banner Section */}
      <Box
        sx={{
          backgroundImage: `url(${photoBg})`,
          backgroundSize: "cover",
          padding: "100px 20px",
          textAlign: "center",
          color: "#fff",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 600,
            textShadow: "1px 1px 0px rgba(0,0,0,0.5)",
            mb: 3,
          }}
        >
          Transform Your Space, Reflect Your Style
        </Typography>
        <Typography
          variant="h6"
          sx={{
            my: 2,
            maxWidth: "600px",
            margin: "auto",
            textShadow: "1px 1px 0px rgba(0,0,0,0.5)",
            lineHeight: "1.5",
          }}
        >
          Every home tells a story. Let us help you create a space that embodies
          your unique vision and enhances your lifestyle.
        </Typography>
      </Box>

      {/* Services Section */}
      <Box sx={{ py: 4, px: 1, textAlign: "center", m: 1 }}>
        <Typography variant="h4" gutterBottom mb={4} fontWeight={"600"}>
          Our Services
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {services.services.map((service, index) => (
            <Grid item xs={10} sm={6} md={4} key={index}>
              <Card
                sx={{
                  padding: 5,
                  transition: "transform 0.3s ease, border-color 0.3s ease",
                  background: index % 2 === 0 ? "#faebf1" : "white",
                  border: "1px solid #803341",
                  boxShadow: "none",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  <span style={{ marginRight: "8px", verticalAlign: "middle" }}>
                    <service.icon size={30} color="#803341" />{" "}
                    {/* Render icon as a component */}
                  </span>
                  <br />
                  {service.service_name}
                </Typography>
                <Typography variant="body2" color="textSecondary" fontSize={17}>
                  {service.service_content}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Company Profile */}
      <Box
        sx={{
          py: 6,
          px: { xs: 2, sm: 4, md: 6 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: { xs: 4, md: 6 },
        }}
      >
        {/* Image Section */}
        <Box
          sx={{
            flex: 1,
            textAlign: "center",
            order: { xs: 2, md: 1 }, // Show image below text on small screens
          }}
        >
          <img
            src={companyProfile}
            alt="Vista Fusion Studio"
            style={{
              width: "100%",
              maxWidth: "500px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Box>

        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            textAlign: { xs: "center", md: "left" },
            order: { xs: 1, md: 2 }, // Show text above image on small screens
            px: { xs: 2, sm: 3 },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.8rem", md: "2.4rem" },
            }}
          >
            Welcome to Vista Fusion Studio
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              color: "text.secondary",
              textAlign: "start",
              fontSize: { xs: "0.85rem", sm: "0.95rem" },
            }}
          >
            Vista Fusion Studio combines expertise and innovation to transform
            spaces. With 11 years of experience as Siri Interior, we are a
            collaborative team of architects, civil engineers, and interior
            designers dedicated to crafting spaces that suit your needs.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 4,
              color: "text.secondary",
              fontSize: { xs: "0.85rem", sm: "0.95rem" },
            }}
          >
            From meticulous space planning and personalized material selection
            to full-service interior design for homes, offices, healthcare, and
            hospitality projects, we tailor every detail to perfection. At Vista
            Fusion Studio, every project tells a story—yours.
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/contact"
            endIcon={<BsArrowRight />}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            sx={{
              color: "#CDA274",
              backgroundColor: "#292F36",
              "&:hover": { backgroundColor: "#1a202c" },
              fontSize: { xs: "0.85rem", sm: "1rem" },
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Box>

      {/* About Us Section */}
      <Box
        sx={{
          py: 6,
          px: { xs: 2, sm: 4, md: 6 },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: { xs: 4, md: 6 },
        }}
      >
        {/* Text Section */}
        <Box
          sx={{
            flex: 1,
            textAlign: { xs: "center", md: "left" },
            px: { xs: 2, sm: 3 },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "1.8rem", md: "2.4rem" },
            }}
          >
            We Design Spaces for Elegant, Stylish Living
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 3,
              color: "text.secondary",
              fontSize: { xs: "0.85rem", sm: "0.95rem" },
            }}
          >
            Our philosophy is rooted in creating spaces that inspire and
            elevate. We skillfully blend functionality with aesthetic finesse to
            design interiors that mirror your unique personality and lifestyle.
          </Typography>
          <Typography
            variant="body2"
            sx={{
              mb: 4,
              color: "text.secondary",
              fontSize: { xs: "0.85rem", sm: "0.95rem" },
            }}
          >
            What sets us apart is our innovative, client-focused approach,
            environmentally-conscious solutions, and our ability to balance
            modern elegance with timeless quality. Each project is a
            masterpiece, thoughtfully curated to make your space feel truly one
            of a kind.
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/contact"
            endIcon={<BsArrowRight />}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            sx={{
              color: "#CDA274",
              backgroundColor: "#292F36",
              "&:hover": { backgroundColor: "#1a202c" },
              fontSize: { xs: "0.85rem", sm: "1rem" },
            }}
          >
            Get Free Estimate
          </Button>
        </Box>

        {/* Image Section */}
        <Box
          sx={{
            flex: 1,
            textAlign: "center",
          }}
        >
          <img
            src={galleryImg}
            alt="Stylish Living Concept"
            style={{
              width: "100%",
              maxWidth: "500px",
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Box>
      </Box>

      {/* Testimonials Section */}
      <Thoughts thoughts={thoughts} customer={customer1} />

      {/* Projects Section */}
      <Box sx={{ py: 5, px: 2, textAlign: "center" }}>
        <Typography variant="h4" gutterBottom fontWeight={"600"}>
          Gallery
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Explore our diverse gallery, where innovative design meets
          functionality.
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {[galleryImg].map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper
                elevation={3}
                sx={{
                  p: 3,
                  backgroundImage: `url(${project})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  color: "#fff",
                  height: 300,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{ bgcolor: "rgba(0, 0, 0, 0.6)", p: 2, width: "100%" }}
                >
                  <Button
                    component={Link}
                    to="/gallery"
                    onClick={() => {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    variant="text"
                    endIcon={<IoIosArrowForward />}
                    sx={{
                      color: "white",
                      fontSize: "large",
                      fontWeight: "700",
                    }}
                  >
                    Visit
                  </Button>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Services;
