import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import vistaMobile from "../images/vista-mobile.png";

const NavBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setDrawerOpen(open);
    };

  const navItems = [
    { text: "Services", path: "/services" },
    { text: "Contact", path: "/contact" },
    { text: "Gallery", path: "/gallery" },
  ];

  const handleNavClick = (path: string) => {
    navigate(path); // Navigate to the specified path
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top
    setDrawerOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
        color: "black",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left Section: Logo and Company Name */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // flexDirection: isMobile ? "column" : "row",
            mb: 1,
          }}
        >
          <img
            src={vistaMobile}
            alt="logo"
            style={{ width: "100%", maxWidth: isMobile ? "30px" : "40px" }}
            onClick={() => {
              navigate("/services");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
          {!isMobile ? (
            <Typography
              variant="h5"
              component="div"
              fontWeight={700}
              sx={{ ml: 2, whiteSpace: "nowrap", color: "#803341" }}
            >
              Vista Fusion Studio
            </Typography>
          ) : (
            <>
              <Typography
                variant="h4"
                component="div"
                fontWeight={700}
                fontSize={"medium"}
                sx={{ whiteSpace: "nowrap", color: "#803341", ml: 1 }}
              >
                Vista Fusion Studio
              </Typography>
            </>
          )}
        </Box>

        {/* Right Section: Navigation Menu */}
        {isMobile ? (
          <>
            <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <List sx={{ width: 180 }}>
                {navItems.map((item) => (
                  <ListItem key={item.text} disablePadding>
                    <ListItemButton
                      onClick={() => handleNavClick(item.path)} // Trigger navigation and scroll
                      sx={{
                        position: "relative",
                        fontWeight:
                          location.pathname === item.path ? "bold" : "normal",
                        textAlign: "center",
                        px: 2,
                        py: 1,
                        background:
                          location.pathname === item.path ? "#faebf1" : "white",
                        ":hover": {
                          background: "white",
                        },
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          height: "2px",
                          width: location.pathname === item.path ? "100%" : "0",
                          backgroundColor: "#803341", // Active/hover underline color
                          transition: "width 0.3s ease-out",
                        },
                        "&:hover::after": {
                          width: "100%",
                        },
                      }}
                    >
                      <ListItemText
                        primary={item.text}
                        primaryTypographyProps={{
                          textAlign: "center",
                          fontWeight:
                            location.pathname === item.path ? "bold" : "normal",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <Box>
            {navItems.map((item) => (
              <Button
                key={item.text}
                color="inherit"
                onClick={() => handleNavClick(item.path)} // Trigger navigation and scroll
                sx={{
                  position: "relative",
                  px: 2,
                  py: 1,
                  fontSize: "1rem",
                  fontWeight:
                    location.pathname === item.path ? "bold" : "normal",
                  textTransform: "none",
                  ":hover": {
                    background: "white",
                  },
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    height: "2px",
                    width: location.pathname === item.path ? "100%" : "0",
                    backgroundColor: "#803341", // Active/hover underline color
                    transition: "width 0.3s ease-out",
                  },
                  "&:hover::after": {
                    width: "100%",
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
